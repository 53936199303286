/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import {
    Grid, withStyles, CircularProgress,
} from '@material-ui/core';
import React, {  useContext, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import ReactHTMLParser from 'react-html-parser';
import { compose } from 'recompose';

import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';

import {
    arrayOf, number, object, string, shape, bool, func,
} from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import GraphqlProductImageUI from '../../ProductImageUI/GraphqlProductImageUI';
import YotpoProductReview from '../../YotpoProductReview';
import ProductReview from '../../ProductReview';
import AgeZipVerify from '../../CommonProductAgeZipVerify/CommonProductAgeZipVerify';
import MobileFoodAddToCart from '../../GraphqlFoodAddToCart/Mobile/MobileFoodAddToCart';
import { getEnterpriseId } from '../../../../../../../state/ducks/Member/ducks/EnterpriseId/EnterpriseId-Selectors';
import { getIsBot, getUserSubmittedProductFilterZipcode } from '../../../../../../../state/ducks/App/App-Selectors';
import DynamicPrice from '../DynamicPrice';
import { getFeatureFlags, getPresentationFamily } from '../../../../../../../state/ducks/App/ducks/Config/Config-Selectors';
import { CrossMerch } from '../../../../CrossMerchContainer/CrossMerchContainer';
import replaceSpecialChar from '../../../../../../helpers/replaceSpecialChar';

const styles = (theme) => ({
    productImageUI: {
        padding: '0px 10px 0px 10px',
        minWidth: '50%',
    },
    CircularProgress: {
        textAlign: 'center',
        margin: '0 auto',
    },
    quickUpsellName: {
        color: theme.palette.cms?.pdpQuickView,
        fontFamily: theme.typography.fontFamily,
        padding: '0 5px',
        width: '82%',
        float: 'left',
        fontWeight: '700',
        fontStyle: 'normal',
        fontSize: '18px',
    },
    quickUpsellPrice: {
        color: theme.palette.cms?.pdpQuickView,
        fontFamily: theme.typography.fontFamily,
        fontWeight: '700',
        fontStyle: 'normal',
        fontSize: '18px',
    },
    skuSelectListContainer: {
        width: '100%',
        '& $crossedOutRetailPrice': {
            paddingRight: '5px',
        },
    },
    skuSelectList: {
        padding: '5px 32px 5px 5px',
        minHeight: '32px',
        display: 'flex',
        alignItems: 'center',
    },
    skuSelectListItemName: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '0.85em',
        whiteSpace: 'normal',
        textTransform: 'capitalize',
    },
    skuSelectListItemPrice: {
        textAlign: 'end',
        display: 'flex',
        alignItems: 'center',
        '& > div': {
            justifyContent: 'flex-end',
            display: 'flex',
            flexWrap: 'wrap',
        },
    },
    textArea: {
        width: '100%',
        height: 270,
        resize: 'none',
        overflowY: 'scroll',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderRadius: '0px',
        borderColor: theme.palette.cms?.skuAvailableToShipTextColor,
        marginTop: '10px',
        padding: '3px',
        boxSizing: 'border-box',
    },
    rootTextArea: {
        padding: '15px 0px 0px 0px',
        '& #contAddToCartBtn': {
            '& >button': {
                minWidth: '100%',
            },
        },
    },
    contentTitle: {
        fontWeight: '700',
        padding: '3px 0px 3px 3px',
    },
    longDescMarkDown: {
    },
    altImagesContainer: {
        [theme.breakpoints.up(1279)]: {
            maxHeight: 425,
            overflowY: 'auto',
            flexWrap: 'wrap',
        },
        flexWrap: 'nowrap',
        '&::-webkit-scrollbar': {  /* Hide scrollbar for Chrome, Safari and Opera */
            display: 'none',
        },
        '-ms-overflow-style': 'none',  /* IE and Edge */
        scrollbarWidth: 'none',  /* Firefox */
    },
    zipCodeInputValid: {
        '&::after': {
            position: 'absolute',
            zIndex: '999',
            backgroundColor: theme.palette.colorValid,
            color: theme.palette.white,
            textAlign: 'center',
            content: '"✓"',
            margin: '-60px 0px 0px 95px',
            fontSize: '18px',
            padding: '5px',
            fontWeight: 'bold',
            lineHeight: '17px',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            animation: 'fadeZoomIn 0.3s ease-in',
            display: 'inline-block', // IE 11
        },
    },
    zipCodeInputInvalidIcon: {
        '&::after': {
            position: 'absolute',
            zIndex: '999',
            backgroundColor: theme.palette.cms?.errorZipVerifyPDP,
            color: theme.palette.white,
            textAlign: 'center',
            content: '"X"',
            fontSize: '17px',
            padding: '5px',
            fontWeight: 'bold',
            margin: '-32px 0px 0px 96px',
            lineHeight: '17px',
            width: '30px',
            height: '30px',
            top: 0,
            borderRadius: '50%',
            animation: 'fadeZoomIn 0.3s ease-in',
            display: 'inline-block', // IE 11
        },
    },
    zipInputTextValid: {
        position: 'relative',
        '&::before': {
            position: 'absolute',
            zIndex: '999',
            color: theme.palette.colorValid,
            lineHeight: '25px',
            fontSize: '14px',
            fontWeight: '400',
            content: '"ZIP code confirmed eligible "',
            margin: '10px 0px 0px -100px',
            border: 'none',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
        },
    },
    zipCodeInputInvalid: {
        position: 'relative',
        '&::before': {
            position: 'absolute',
            zIndex: '999',
            color: theme.palette.cms?.errorZipVerifyPDP,
            lineHeight: '25px',
            fontSize: '14px',
            fontWeight: '400',
            content: '"Not eligible for Wine shipment"',
            bottom: '-34px',
            left: 0,
            paddingLeft: '0',
            width: '250px',
            height: '25px',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
        },
    },
    ageInput: {
        width: '100%',
        display: 'flex',  // IE 11
        padding: '8px 0px 8px 0px',
        '& > div': {
            width: '100%', // IE 11
        },
    },
    ageInputInvalid: {
        position: 'relative',
        '&::before': {
            position: 'absolute',
            zIndex: '999',
            color: theme.palette.cms?.errorZipVerifyPDP,
            lineHeight: '25px',
            fontSize: '11px',
            border: 'none',
            fontWeight: '200',
            content: '"You must be 21 or older to purchase wine!"',
            height: '22px',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
        },
    },
    dateInvalid: {
        position: 'relative',
        '&::before': {
            position: 'absolute',
            zIndex: '999',
            color: theme.palette.cms?.errorZipVerifyPDP,
            lineHeight: '25px',
            fontSize: '12px',
            border: 'none',
            fontWeight: '200',
            margin: '0px 0px 0px 20px',
            content: '"Please enter a valid date of birth !"',
            height: '22px',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
        },
    },
    ageInputInvalidIcon: {
        '&::after': {
            position: 'absolute',
            zIndex: '999',
            margin: '-50px 0px 0px 205px',
            backgroundColor: theme.palette.cms?.errorZipVerifyPDP,
            color: theme.palette.white,
            textAlign: 'center',
            content: '\'\\2715\'',
            fontSize: '20px',
            padding: '3px',
            fontWeight: 'bold',
            lineHeight: '17px',
            left: '7px',
            width: '25px',
            height: '25px',
            borderRadius: '50%',
            animation: 'fadeZoomIn 0.3s ease-in',
            display: 'inline-block', // IE 11
        },
    },
    grid: {
        width: '250px',
        height: '90px',
    },
    ageInputTextValid: {
        position: 'relative',
        '&::before': {
            position: 'absolute',
            zIndex: '999',
            color: theme.palette.colorValid,
            lineHeight: '25px',
            fontSize: '14px',
            fontWeight: '400',
            content: '"Age Confirmed"',
            width: '95px',
            margin: '0px 0px 0px 12px',
            border: 'none',
            transition: 'opacity 0.3s ease-in',
            opacity: 1,
        },
    },
    ageInputValid: {
        '&::after': {
            position: 'absolute',
            zIndex: '999',
            backgroundColor: theme.palette.colorValid,
            color: theme.palette.white,
            textAlign: 'center',
            content: '"✓"',
            margin: '-50px 0px 0px 215px',
            fontSize: '20px',
            padding: '7px',
            fontWeight: 'bold',
            lineHeight: '17px',
            width: '30px',
            height: '30px',
            borderRadius: '50%',
            animation: 'fadeZoomIn 0.3s ease-in',
            display: 'inline-block', // IE 11
        },
    },
    errorMessage: {
        color: theme.palette.error?.main,
        padding: '5px 20px',
    },
    crossedOutRetailPrice: {
        textDecoration: 'line-through',
        fontWeight: '400',
        paddingRight: '10px',
        fontSize: '15px',
    },
    salePrice: {
        color: theme.palette.pdp.salePrice || '#a1001a',
        fontWeight: '700',
        fontSize: '15px',
    },
    offPrice: {
        fontWeight: '700',
        display: 'flex',
        fontSize: '15px',
        color: theme.palette.contentColor,
    },
    linkWrapper: {
        justifyContent: 'flex-end',
        '& $seeDetailsLink': {
            padding: '7px 10px 5px 10px',
        },
        [theme.breakpoints.down(1366)]: {
            '& > div': {
                maxWidth: '300px',
                flexBasis: '300px',
            },
        },
    },
    seeDetailsLink: {
        color: theme.palette.contentColor,
        backgroundColor: 'transparent',
        border: `2px solid ${theme.palette.contentColor}`,
        textDecoration: 'none',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: 50,
        padding: '7px 24px 5px 24px',
        fontSize: '1em',
        fontWeight: 700,
        textTransform: 'uppercase',
    },
    quickContainer: {
        marginTop: '5px',
        '& $quickUpsellName': {
            maxWidth: '72%',
            flexBasis: '72%',
        },
        '& $quickUpsellPrice': {
            maxWidth: '28%',
            flexBasis: '28%',
        },
    },
    quickViewMain: {
        padding: '8px',
        cursor: 'pointer',
        lineHeight: '1.5',
    },
});

const FoodQuickViewContent = ({
    classes,
    product,
    quickViewData,
    selectedName,
    partNumber,
    productSkus: productSkusData,
    shortDescription,
    image,
    productUrl,
    quickViewLongDesc,
    location,
    brand,
    addToCartHide,
    handleMiniCartModalClick,
    enableMiniCart,
    trackEvent,
    hasWine,
    enterpriseId,
    isBot,
    currentBrandPresentationFamily,
    currentBrandFeatureFlags,
    userSubmittedProductFilterZipcode,
    categoryId,
    categoryPath,
    categoryName,
}) => {
    // need to use context & feature flags
    const quickView = JSON.parse(JSON.stringify(quickViewData)); // to avoid object extensible error!
    const productSkus = JSON.parse(JSON.stringify(productSkusData?.length > 0 ? productSkusData : [quickViewData])); // to avoid object extensible error!
    const { featureFlags: productBrandsFeatureFlags = {}, presentation_family: productBrandsPresentationFamily } = useContext(CrossMerch);
    const [open, setOpen] = useState(false);
    const [addToCartData, setAddToCartData] = useState(null);
    const [selectedSku, setSelectedSku] = useState(quickView);
    const selectedQuickViewData = productSkusData?.length > 0  ? product : selectedSku;
    const [dynamicPriceIsLoading, setDynamicPriceLoading] = useState(false);
    const [productSkuPrices, setProductSkuPrices] = useState([]);
    const [priceRule, setPriceRule] = useState(null);
    const presentationFamily = productBrandsPresentationFamily || currentBrandPresentationFamily;
    const featureFlags = Object.keys(productBrandsFeatureFlags)?.length > 0 ? productBrandsFeatureFlags : currentBrandFeatureFlags;
    const isDynamicPriceEnabled = featureFlags['is-dynamic-pricing-enabled'];
    const wineModelOnAction = featureFlags['is-inline-wine-verification-model-on-atc-enabled'] || false;
    // declaring Dynamic price call instance
    const dynamicPriceInstance = new DynamicPrice(productSkus, enterpriseId);
    const {
        getPriceRules,  getRetailPrice, getSalePrice,
    } = dynamicPriceInstance;

    useEffect(() => {
        if (isBot || !isDynamicPriceEnabled) {
            setDynamicPriceLoading(false);
            return;
        }
        if (featureFlags['is-optimize-script-enabled'] && isDynamicPriceEnabled) {
            return;
        }

        const useZipcode = featureFlags['is-zip-dynamic-pricing-enabled'];

        setDynamicPriceLoading(true);
        dynamicPriceInstance.call(useZipcode, userSubmittedProductFilterZipcode).then((productPrices) => {
            const priceRules = getPriceRules(selectedSku?.id);
            setPriceRule(priceRules);
            setProductSkuPrices(productPrices || []);
            setDynamicPriceLoading(false);
        }).catch(() => {
            setDynamicPriceLoading(false);
        });
    }, [
        userSubmittedProductFilterZipcode,
        setDynamicPriceLoading,
        setProductSkuPrices,
        featureFlags,
    ]);

    const  handleAgeVerification = (flag, cartData = null) => {
        setOpen(flag);
        setAddToCartData(flag ? cartData : null);
    };

    if (!selectedSku.ageVerifyFlag && typeof selectedSku.ageVerifyFlag === 'undefined') {
        setSelectedSku({ ageVerifyFlag: hasWine, ...selectedSku });
    }

    const deliveryData = product?.content?.entries?.[0].delivery_section;
    const navigateUrl = quickViewData?.seo?.url || productUrl;
    const handleSkuChange = (e) => {
        const newSku = productSkus.find((sku) => sku.id === e.target.value);
        if (newSku) {
            setSelectedSku(newSku);
        }
    };

    // Gets the value of sale price from the sku.
    const renderSkuPrice = (skuData, showPriceOff, skuDynamicPrice) => {
        const round = (value) => Number(`${Math.round(value * 10) / 10}`).toFixed(2);
        const retailPrice = getRetailPrice(skuDynamicPrice) > 0 ? getRetailPrice(skuDynamicPrice) :  skuData?.prices?.find((priceData) => priceData.type === 'retail')?.value;
        const salePrice = getSalePrice(skuDynamicPrice) > 0 ? getSalePrice(skuDynamicPrice) :  skuData?.prices?.find((priceData) => priceData.type === 'sale')?.value;
        const defaultPrice = `$${parseFloat(retailPrice).toFixed(2)}`;
        const offPrice = `${round(retailPrice - salePrice)} off` || '';
        return (
            <Grid item xs={12} className={classes.setItemLayout}>
                {salePrice && retailPrice && salePrice !== retailPrice
                    ? (
                        <>
                            <s>
                                ${retailPrice}
                            </s>
                            <span className={classes.salePrice}>
                                ${salePrice}
                            </span>
                            {showPriceOff && (
                                <span className={classes.offPrice}>
                                    ${offPrice}
                                </span>
                            )}

                        </>
                    )
                    : <span className={classes.defaultPrice}> {defaultPrice}</span>}
            </Grid>
        );
    };

    const renderNameAndPrice = () => {
        if (productSkus.length > 1) {
            return (
                <>
                    <Grid item sm={12} lg={12} className={classes.quickUpsellName}>
                        <Typography paragraph variant="h5">{ReactHTMLParser(replaceSpecialChar(selectedName, false))}</Typography>
                    </Grid>
                    {!dynamicPriceIsLoading ? (
                        <Select
                            data-testid="food-quickview-select"
                            value={selectedSku.id}
                            onChange={handleSkuChange}
                            label="Age"
                            variant="outlined"
                            className={classes.skuSelectListContainer}
                            classes={{ root: classes.skuSelectList }}
                        >
                            {productSkus.map((sku) => {
                                const skuItemPrice = productSkuPrices?.find?.((item) => item?.id === sku?.id)?.prices || [];
                                const skuItemName = ReactHTMLParser(sku.name);
                                return (
                                    <div key={`quickview-${sku.id}`} value={sku.id}>
                                        <Grid container className={classes.quickViewMain}>
                                            <Grid item xs="8" className={classes.skuSelectListItemName}>
                                                {skuItemName}
                                            </Grid>
                                            <Grid jus item xs="4" className={classes.skuSelectListItemPrice}>
                                                {renderSkuPrice(sku, false,  skuItemPrice)}
                                            </Grid>
                                        </Grid>
                                    </div>
                                );
                            })}
                        </Select>
                    ) : (
                        <>
                            <CircularProgress className={classes.CircularProgress} />
                        </>
                    )}

                </>
            );
        }

        const skuItemPrice = productSkuPrices?.find?.((item) => item?.id === (selectedSku?.id || quickViewData?.id))?.prices || [];
        return (
            <>
                <Grid item sm={9} lg={9} className={classes.quickUpsellName}>
                    <span>{ReactHTMLParser(replaceSpecialChar(selectedName, false))}</span>
                </Grid>
                <Grid item sm={3} lg={3} className={classes.quickUpsellPrice}>
                    {!dynamicPriceIsLoading ? (<Grid container>{renderSkuPrice(selectedSku?.length > 0 ? selectedSku : quickViewData, true, skuItemPrice)}</Grid>) : (<CircularProgress className={classes.CircularProgress} />)}
                </Grid>
            </>
        );
    };

    /**
     * Setting Price RuleID
     */

    if (priceRule?.length > 0) {
        selectedSku.priceRules = priceRule;
    } else if (selectedSku.priceRules) {
        selectedSku.priceRules = []; // clearing price rule
    }
    const productContentSku = product.productSkus || [];
    const requiresUserInteraction = selectedSku?.productType === 'CUSTOM_ASSORTMENT' || (product.brandId === '1030' || product.brandId === '1033') || (productContentSku?.[0]?.personalization?.lines?.length > 0 && productContentSku?.[0]?.personalization?.indicator && featureFlags['is-personalization-pdp-enabled']);
    const compileSelectedItems = () => {
        const selectedMonthSku = product?.club?.defaultSku?.components.find((item) => item?.month?.toLowerCase() === selectedSku?.date?.month?.toLowerCase());
        const productArray = [];
        productArray.push({
            brandCode: selectedSku?.brandId,
            productCode: selectedMonthSku?.partNumber,
            quantity: selectedSku?.quantity || 1,
            componentType: selectedSku?.productType,
        });
        return productArray;
    };
    return (
        <>
            <Grid item sm={6} lg={6} className={classes.productImageUI}>
                <GraphqlProductImageUI altImagesContainerStyle={classes.altImagesContainer} data={selectedQuickViewData} selectedSku={selectedSku} location={location}  partNumber={partNumber} />
            </Grid>
            <Grid item sm={6} lg={6}>
                <Grid container className={!(productSkus.length > 1) ? classes.quickContainer : ''}>
                    {renderNameAndPrice()}
                </Grid>
                <Grid container item sm={12} lg={12} className={classes.rootTextArea}>
                    <Grid item sm={6} lg={6} className={classes.quickUpsellYotpo}>
                        {featureFlags['is-pdp-reviews-enabled'] && featureFlags['is-power-review-enabled'] && (
                            <ProductReview
                                productUrl={navigateUrl}
                                name={selectedName}
                                partNumber={partNumber}
                                shortDescription={shortDescription}
                                image={image}
                            />
                        )}
                        {featureFlags['is-pdp-reviews-enabled'] && featureFlags['is-pdp-yotpo-enabled'] && (
                            <YotpoProductReview
                                productUrl={navigateUrl}
                                name={selectedName}
                                partNumber={partNumber}
                                shortDescription={shortDescription}
                                image={image}
                            />
                        )}
                    </Grid>
                    <div className={classes.textArea}>
                        <div className={classes.contentTitle}><span>Contents</span></div>
                        {
                            quickViewLongDesc
                                ? <ReactMarkdown source={selectedSku?.longDescription || product?.longDescription || quickViewLongDesc} escapeHtml={false} className={classes.longDescMarkDown} />
                                : null
                        }
                    </div>
                </Grid>
                <Grid container spacing={2} sm={12} lg={12} className={`${classes.rootTextArea} ${requiresUserInteraction ? classes.linkWrapper : ''}`}>
                    <Grid item sm={6} lg={6} className={classes.quickUpsellYotpo}>
                        <Link
                            className={classes.seeDetailsLink}
                            to={navigateUrl}
                            onClick={() => {
                                trackEvent({
                                    eventCategory: 'Quick View',
                                    eventAction: 'See details',
                                    eventLabel: '',
                                });
                            }}
                        > {requiresUserInteraction ? 'Make Your Selection' : 'See Details' }
                        </Link>
                    </Grid>
                    {!requiresUserInteraction
                    && (
                        <Grid item sm={6} lg={6} className={classes.quickUpsellYotpo}>
                            {addToCartHide
                            && (
                                <MobileFoodAddToCart
                                    selectedItemId={selectedSku.id}
                                    brandId={quickViewData?.brandId}
                                    partNumber={partNumber}
                                    productType={quickViewData?.productType}
                                    totalProductSelected={1}
                                    product={product}
                                    hasWine={hasWine}
                                    isMobile
                                    isAddToCartEnabled={hasWine}
                                    disableAddToCart={false}
                                    handleMobileAgeVerification={handleAgeVerification}
                                    enableMinicart={enableMiniCart}
                                    handleMiniCartModalClick={handleMiniCartModalClick}
                                    actionType="Quick View"
                                    selectedSku={selectedSku}
                                    quickView={!wineModelOnAction}
                                    categoryId={categoryId}
                                    categoryName={categoryName}
                                    categoryPath={categoryPath}
                                    setOpen={() => {}}
                                />
                            )}
                        </Grid>
                    )}
                </Grid>

                {open && hasWine && addToCartData && !wineModelOnAction
                && (
                    <AgeZipVerify
                        classes={classes}
                        selectedSku={selectedSku}
                        deliveryData={deliveryData}
                        productSkus={selectedSku}
                        brand={brand}
                        mobileAgeVerificationOpen={open}
                        handleMobileAgeVerification={handleAgeVerification}
                        addToCartData={addToCartData}
                        modal
                        forceModal
                        featureFlags={featureFlags}
                        presentationFamily={presentationFamily}
                        components={selectedSku?.productType === 'CLUB' ? compileSelectedItems() : []}
                        defaultSku={selectedSku?.productType === 'CLUB' ? product?.club?.defaultSku?.partNumber : selectedSku?.id}
                    />
                )}
            </Grid>
        </>
    );
};
FoodQuickViewContent.propTypes = {
    classes: object.isRequired,
    selectedName: string.isRequired,
    quickViewLongDesc: string.isRequired,
    location: shape({
        search: string,
    }),
    userSubmittedProductFilterZipcode: string.isRequired,
    productUrl: string.isRequired,
    partNumber: string.isRequired,
    shortDescription: string.isRequired,
    image: shape({
        name: string.isRequired,
        path: string.isRequired,
    }).isRequired,
    product: shape({
        name: string.isRequired,
        partNumber: string.isRequired,
        shortDescription: string.isRequired,
        image: shape({
            name: string.isRequired,
            path: string.isRequired,
        }).isRequired,
        availability: shape({
            deliveryMessage: string.isRequired,
            productDeliveryType: string.isRequired,
        }).isRequired,
    }),
    quickViewData: shape({
        id: string.isRequired,
        name: string.isRequired,
        prices: arrayOf(
            shape({
                type: string.isRequired,
                value: number.isRequired,
                __typename: string.isRequired,
            }),
        ).isRequired,
        __typename: string.isRequired,
    }),
    productSkus: arrayOf(
        shape({
            id: string,
            name: string,
            prices: arrayOf(
                shape({
                    type: string,
                    value: number,
                    __typename: string,
                }),
            ),
        }),
    ),
    brand: shape({
        domain: string,
    }),
    addToCartHide: bool,
    enableMiniCart: bool,
    handleMiniCartModalClick: func,
    trackEvent: func,
    hasWine: bool,
    isBot: bool,
    enterpriseId: string,
    currentBrandPresentationFamily: string,
    currentBrandFeatureFlags: object,
    categoryId: string,
    categoryPath: string,
    categoryName: string,
};
FoodQuickViewContent.defaultProps = {
    location: {},
    product: {},
    quickViewData: {},
    productSkus: [],
    brand: {},
    addToCartHide: false,
    enableMiniCart: false,
    hasWine: false,
    handleMiniCartModalClick: () => {},
    trackEvent: () => {},
    isBot: false,
    enterpriseId: '',
    currentBrandPresentationFamily: '',
    currentBrandFeatureFlags: {},
    categoryId: '',
    categoryPath: '',
    categoryName: '',
};

const mapStateToProps = (state) => ({
    enterpriseId: getEnterpriseId(state),
    isBot: getIsBot(state),
    currentBrandFeatureFlags: getFeatureFlags(state),
    currentBrandPresentationFamily: getPresentationFamily(state),
    userSubmittedProductFilterZipcode: getUserSubmittedProductFilterZipcode(state),
});

const enhance = compose(
    withStyles(styles),
    connect(mapStateToProps),
);

export default enhance(FoodQuickViewContent);
